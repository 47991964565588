html,body, #root{
  height: 100%;
}
body{
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
iframe {
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#viewport {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content:center;
  align-items:center;
}